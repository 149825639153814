import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Icon } from "@ryerson/frontend.assets";
import { useTheme } from "@ryerson/frontend.theme";
import { Video } from "@ryerson/frontend.media";
import { Carousel, ISlideBaseProps } from "@ryerson/frontend.carousel";
import { WhatOurContentSays } from "./RefactorContent";
import { STSWhatOurContentSays } from "./STSContent";
import { ContentSection } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export type Slide = {
	quote: string;
	person: string;
	personLabel: string;
};

export type WhatOurCustomersSayContent = {
	title: string;
	navLabel: string;
	videoId: string;
	thumbnailUrl?: string;
	slides: Slide[];
};

export interface ICustomSlideProps extends ISlideBaseProps {
	quote: string;
	person: string;
	personLabel: string;
	videoId: string;
	thumbnailUrl?: string;
}

export type WhatOurCustomersSayProps = {
	content?: WhatOurCustomersSayContent;
};

const SlideContainer = styled.div`
	display: block;
	width: 100%;
	height: 560px;
	position: relative;
`;

const SlideQuote = styled.div`
	display: block;
	width: calc(100% - 130px);
	max-width: 760px;
	height: auto;
	position: absolute;
	top: 50px;
	left: 0px;
`;

const SlideVideo = styled.div`
	display: block;
	width: 560px;
	height: 360px;
	position: absolute;
	bottom: 0px;
	right: 0px;
`;

const SlideButtons = styled.div`
	display: block;
	position: absolute;
	top: 50px;
	width: 125px;
	height: 60px;
	right: 0px;
`;

const CircleIcon = styled.div`
	display: inline-block;
	width: 60px;
	height: 60px;
	border-radius: 60px;
	text-align: center;
	line-height: 67px;
	cursor: pointer;
`;

const VideoSlide: React.FC<ICustomSlideProps> = ({
	back = () => {},
	next = () => {},
	quote,
	person,
	personLabel,
	videoId,
	thumbnailUrl = null,
}) => {
	const { theme } = useTheme();
	return (
		<SlideContainer
			css={css`
				@media (max-width: ${theme.breakpoints.lg}) {
					min-height: 600px;
					height: auto;
					padding-bottom: 40px;
				}
			`}
		>
			<SlideButtons
				css={css`
					@media (max-width: ${theme.breakpoints.lg}) {
						top: 220px;
						width: 85px;
						height: 40px;
						right: unset;
						left: 195px;
						z-index: 2;
					}
				`}
			>
				<CircleIcon
					onClick={() => {
						back();
					}}
					css={css`
						background-color: ${theme.colors.primary.darkGray};
						margin-right: 5px;
						@media (max-width: ${theme.breakpoints.lg}) {
							width: 40px;
							height: 40px;
							border-radius: 40px;
							line-height: 42px;
						}
					`}
				>
					<Media greaterThanOrEqual="lg">
						<Icon icon="chevron-left" color={theme.colors.primary.white} size="sm" />
					</Media>
					<Media lessThan="lg">
						<Icon icon="chevron-left" color={theme.colors.primary.white} size="xs" />
					</Media>
				</CircleIcon>
				<CircleIcon
					onClick={() => {
						next();
					}}
					css={css`
						background-color: ${theme.colors.primary.darkGray};
						@media (max-width: ${theme.breakpoints.lg}) {
							width: 40px;
							height: 40px;
							border-radius: 40px;
							line-height: 42px;
						}
					`}
				>
					<Media greaterThanOrEqual="lg">
						<Icon icon="chevron-right" color={theme.colors.primary.white} size="sm" />
					</Media>
					<Media lessThan="lg">
						<Icon icon="chevron-right" color={theme.colors.primary.white} size="xs" />
					</Media>
				</CircleIcon>
			</SlideButtons>

			<Media greaterThanOrEqual="lg">
				<SlideVideo
					css={css`
						@media (max-width: ${theme.breakpoints.xl}) {
							right: 60px;
						}
					`}
				>
					<Video
						buttonOrientation="right"
						imageUrl={
							thumbnailUrl && thumbnailUrl.length > 0 ? thumbnailUrl : undefined
						}
						videoId={videoId}
					/>
				</SlideVideo>
			</Media>
			<Media lessThan="lg">
				<SlideVideo
					css={css`
						@media (max-width: ${theme.breakpoints.lg}) {
							width: 100%;
							max-width: 450px;
							height: 200px;
							bottom: unset;
							right: unset;
							left: px;
							top: 40px;
							z-index: 1;
						}
					`}
				>
					<Video buttonOrientation="top-right" videoId={videoId} />
				</SlideVideo>
			</Media>
			<SlideQuote
				css={css`
					@media (max-width: ${theme.breakpoints.lg}) {
						width: 100%;
						max-width: 450px;
						top: unset;
						bottom: unset;
						left: unset;
						right: unset;
						display: block;
						padding-top: 280px;
						position: relative;
					}
				`}
			>
				<Typography
					variant="h2"
					css={css`
						letter-spacing: -2px;
						position: relative;
						@media (max-width: ${theme.breakpoints.lg}) {
							font-size: 20px !important;
						}
					`}
					color={theme.colors.primary.gray}
				>
					<Typography
						variant="div"
						color={theme.colors.primary.lightGray}
						css={css`
							position: absolute;
							left: -20px;
							@media (max-width: ${theme.breakpoints.lg}) {
								left: -10px;
							}
						`}
					>
						“
					</Typography>
					{quote}
					<Typography
						variant="span"
						css={css`
							display: inline-block;
							margin-left: 5px;
						`}
						color={theme.colors.primary.lightGray}
					>
						”
					</Typography>
				</Typography>
				<Typography
					variant="div"
					css={css`
						margin-top: 48px;
						@media (max-width: ${theme.breakpoints.lg}) {
							margin-top: 28px;
						}
					`}
					size="md"
					type="primary"
					weight="bold"
				>
					{person}
				</Typography>
				<Typography variant="div" size="md" type="primary">
					{personLabel}
				</Typography>
			</SlideQuote>
		</SlideContainer>
	);
};

const WhatOurCustomers: React.FC<WhatOurCustomersSayProps> = ({ content }) => {
	const {
		localization: { language },
	} = useApplication();
	if (!content) {
		content =
			process.env.GATSBY_COMPANY === "southernToolSteel"
				? STSWhatOurContentSays[language]
				: WhatOurContentSays[language];
	}
	return (
		<>
			<Media lessThan="lg">
				<ContentSection hPadding="0px">
					<Carousel header={false} language={language}>
						{content.slides.map((slide) => (
							<VideoSlide
								person={slide.person}
								personLabel={slide.personLabel}
								videoId={content ? content.videoId : ""}
								quote={slide.quote}
								thumbnailUrl={content ? content.thumbnailUrl : undefined}
							/>
						))}
					</Carousel>
				</ContentSection>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="80px">
					<Carousel title={content.title} language={language}>
						{content.slides.map((slide: Slide) => (
							<VideoSlide
								person={slide.person}
								personLabel={slide.personLabel}
								videoId={content ? content.videoId : ""}
								quote={slide.quote}
								thumbnailUrl={content ? content.thumbnailUrl : undefined}
							/>
						))}
					</Carousel>
				</ContentSection>
			</Media>
		</>
	);
};

export default WhatOurCustomers;
